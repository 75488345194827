<template>
  <dash-page-new
      :title="$t('Blocks')"
      :side-navigation="templateId ? avalonAdminTemplateNavigationItems : null"
      :navigation-return-route="localeLink('avalon_editor/templates')"
  >
    <template #header_action>
      <v-btn @click="openNewBlock" :color="wsATTENTION" dark class="noCaps">
        <v-icon>mdi-plus</v-icon>
        {{ $t('CreateNew') }}
      </v-btn>
    </template>

    <v-row  no-gutters  style="flex-wrap: nowrap; height: 100% !important" class="fill-height mt-5"  >
      <!-- Categories-->
      <v-col :style="`min-width: ${categoriesWidth}px; max-width: ${categoriesWidth}px; height: 100% !important;`"
             style="transition: all 0.3s ease"
             class="pr-4 pb-6 flex-grow-0 flex-shrink-0 sideTransition fill-height"
      >
        <v-sheet class="fill-height">
          <v-sheet v-if="!hideCategories"
                   class="fill-height wsRoundedLight overflow-y-auto"
                   :style="`border : 1px solid ${wsBACKGROUND}`"
          >
            <v-sheet :color="wsLIGHTCARD" height="46"
                     class="d-flex  wsRoundedLight align-center"
                     :class="!hideCategories ? 'px-2 justify-space-between' : 'justify-center'"
            >
              <ws-tooltip v-if="!hideCategories" :text="$t('AddCategory')">
                <v-btn :color="wsACCENT"  @click="openAddNewCategory" icon>
                  <v-icon >mdi-folder-plus-outline</v-icon>
                </v-btn>
              </ws-tooltip>

              <h4 v-if="!hideCategories" class="pl-4" > {{ $t('Categories') }}</h4>

              <v-btn @click="hideCategoriesAction" :color="wsACCENT" icon><v-icon>mdi-chevron-{{ !hideCategories ?  'left' : 'right' }}</v-icon></v-btn>

            </v-sheet>

            <v-divider   :style="`border-color : ${wsBACKGROUND}`" ></v-divider>

            <h5>categories</h5>


          </v-sheet>

          <ws-tooltip v-else  :text="$t('Categories')">
            <v-btn @click="hideCategoriesAction" :color="wsACCENT" icon><v-icon>mdi-file-tree</v-icon></v-btn>
          </ws-tooltip>

        </v-sheet>
      </v-col>

      <v-col style="min-width: 100px; max-width: 100%;" class="flex-grow-1 flex-shrink-0 pb-6">
        <v-sheet
                 class="fill-height wsRoundedLight overflow-y-auto"
                 :style="`border : 1px solid ${wsBACKGROUND}`"
        >
        <ws-data-table
            :items="blocks"
            :headers="headers"
            :row-action="selectBlock"
        >
          <template #item.name="{item}">
            <router-link class="black--text linkHover noUnderline" :to="openBlockRoute(item)">
              <h5>{{ item.name }}</h5>
            </router-link>

          </template>
          <template #item.uploads="{item}">

            <div class="d-flex align-center">
              <v-icon class="mr-2" :color="wsACCENT"> mdi-download </v-icon>
              <h5> {{ item.uploads }}</h5>
            </div>

          </template>
        </ws-data-table>
        </v-sheet>
      </v-col>



    </v-row>




    <template #dialog>
      <!-- Category Dialog-->
      <ws-dialog
          v-model="displayCategoryDialog"
          :title="newCategory ? 'Створити категорію' : 'Редагувати категорію' "
          @save="addEditBlockCategory"
      >

        <ws-text-field
            v-model="categoryEntityData.name.ua"
            label="Назва категорії"
            :title="$t('Name') + ' (UA)'"
        />
        <ws-text-field
            v-model="categoryEntityData.name.en"
            label="Category name"
            :title="$t('Name') + ' (EN)'"
        />
        <ws-text-field
            v-model="categoryEntityData.name.ru"
            label="Название категории"
            :title="$t('Name') + ' (RU)'"
        />

      </ws-dialog>
      <!-- Template Dialog-->
      <ws-dialog
          v-model="displayBlockDialog"
          :title="$t('AddNewTemplate')"
          @save="addBlock"
      >

        <ws-text-field
            v-model="entityData.name"
            :label="$t('Name')"
            :placeholder="$t('EnterName')"
        />

        <ws-switch
            v-model="entityData.is_footer"
            :label="$t('IsFooter')"
            :placeholder="$t('IsPageFooter')"
        />

        <ws-switch
            v-model="entityData.is_published"
            :label="$t('IsPublished')"
            :placeholder="$t('IsTemplatePublished')"
        />

      </ws-dialog>
    </template>

  </dash-page-new>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "adminTemplates",
  props : {
    templateId : {
      type : String
    }
  },
  data() {
    return {
      categories : [],
      categoryEntityData : {
        name : {}
      },
      categoriesWidth : 280,
      hideCategories : false,
      displayCategoryDialog : false,
      newCategory : false,

      blocks : [],
      elements : [],
      displayBlockDialog : false,
      entityData : {}
    }
  },
  computed : {
    headers() {
      return [
        { text : 'Name' , value : 'name' },
        { text : 'Uploads' , value : 'uploads' },
        { text : 'Category' , value : 'category_name' },
      ]
    }
  },
  methods : {
    ...mapActions('avalon' , [
        'GET_ADMIN_TEMPLATE_BLOCKS',
        'ADD_BLOCK_CATEGORY'
    ]),
    ...mapActions('avalonPage' , [
        'ADD_BLOCK',
    ]),

    async addEditBlockCategory() {
      let result = await this.ADD_BLOCK_CATEGORY(this.categoryEntityData)
      if ( !result ) {
        return
      }
      this.categories.push(result)
    },
    openAddNewCategory() {
      this.categoryEntityData = {
        name : {}
      }
      this.newCategory = true
      this.displayCategoryDialog = true
    },
    hideCategoriesAction() {
      if (this.hideCategories) {
        this.hideCategories = false
        this.categoriesWidth = 280
        return
      }
      if (!this.hideCategories) {
        this.hideCategories = true
        this.categoriesWidth = 50
      }
    },

    async addBlock() {

      let data = JSON.parse(JSON.stringify(this.entityData))
      if ( this.templateId ) {
        data.template_id = this.templateId
      }
      data.is_template = true

      let result = await this.ADD_BLOCK(data)
      if ( !result ) {
        return
      }
      this.blocks.unshift(result)
      this.displayBlockDialog = false

    },
    openNewBlock() {
      this.entityData = {}
      this.displayBlockDialog = true
    },
    openBlockRoute(item) {
      return !this.templateId
          ? this.localeLink('avalon_editor/blocks/' + item.id )
          : this.localeLink(`avalon_editor/templates/${this.templateId}/blocks/` + item.id )
    },

    selectBlock(item) {
      this.$router.push(this.openBlockRoute(item))
    },
  },
  async mounted() {
    let result = await this.GET_ADMIN_TEMPLATE_BLOCKS(this.templateId)
    if ( !result ) {
      return
    }
    result.blocks.reverse()
    this.blocks = result.blocks
    this.elements = result.elements
    this.categories = result.categories
  }
}
</script>

<style scoped>

</style>